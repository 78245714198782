import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import pkgJson from '../../../package.json';
import { Fetch } from 'src/app/services/fetch.service';

/**
 * Handle ServiceWorker updates and track current portal-ui version
 */
@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    packageVersion = pkgJson.version;
    gatewayVersion: string;

    constructor(
        private readonly swUpdate: SwUpdate,
        private readonly snackbar: MatSnackBar,
        private readonly fetch: Fetch
    ) {
        this.swUpdate.versionUpdates.subscribe((evt) => {
            // Catch installation errors that are not hash mismatches
            if (evt.type == "VERSION_INSTALLATION_FAILED" && !evt.error.includes("Hash mismatch")) {
                console.error(evt);
                window.dtrum?.reportError(evt.error);
            }

            // If the new version is ready for reload
            if (evt.type == "VERSION_READY") {
                const snack = this.snackbar.open('Update Available', 'Reload');

                snack
                    .onAction()
                    .subscribe(() => {
                        window.location.reload();
                    });

                setTimeout(() => {
                    snack.dismiss();
                }, 6000);
            }
        });

        if (this.swUpdate.isEnabled)
            this.swUpdate.checkForUpdate();

        fetch.get<{ version: string; }>('/api/portal/_version').then(v => {
            if (!v) return;
            this.gatewayVersion = v.version;
        });
    }
}
